import { useTranslation } from "app";
import { IDataLayer } from "domain/SeoAndAnalytics/Seo";
import { Language } from "i18n/country";
import { languageConfig } from "i18n/language-config";
import { getPageName } from "../utils/getPageName";
import { useRouter } from "next/router";
import { PageTypes } from "domain/SeoAndAnalytics/GoogleAnalytics/utils";
import { RecordCheckStore } from "stores/recordCheck.store";
import { useStores } from "hooks";

export const useDataLayer = (pageTitle: string, pageType: string, withRecordCheckData: boolean = false): IDataLayer => {
  const { i18n } = useTranslation();
  const { recordCheckStore } = useStores();
  const { pathname, query } = useRouter();
  const languageShort = languageConfig.mapLocaleToLanguageShort(i18n.language);
  const pageCountry = languageShort.toUpperCase() || Language.ENGLISH.toUpperCase(); //TODO: this fallback can be removed once the issue is fixed that the language gets lost when you have a programatic redirect through router
  const pageLocale = languageShort || Language.ENGLISH.toLowerCase(); //TODO: this fallback can be removed once the issue is fixed that the language gets lost when you have a programatic redirect through router
  const pageName = getPageName(pathname, pageLocale);
  const vin = query?.vin || query?.plate_encoded;
  const vinHashed = !!query?.plate_encoded;
  const dataLayerSummary = {
    pageName,
    pageTitle,
    pageType,
    pageCountry,
    pageLocale,
    ...(pageType === PageTypes.PARTNER_LANDING_PAGE && { vin, vinHashed }),
    vinEntries: undefined,
  };
  return withRecordCheckData ? attachVinDataToDatalayer(dataLayerSummary, recordCheckStore) : dataLayerSummary;
};

const attachVinDataToDatalayer = (dataLayer: IDataLayer, recordCheck: RecordCheckStore): IDataLayer => {
  return {
    ...dataLayer,
    vin: recordCheck.isEncrypted ? recordCheck.encryptedQuery : recordCheck.query,
    vinHashed: recordCheck.isEncrypted,
    vinEntries: recordCheck.reportDataTotal,
  };
};
